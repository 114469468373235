html {
  /* em 相对父元素 */
  /* rem 相对根元素，r 就是 root 的意思 */
  /* font-size 默认是 16px，16px * 62.5% = 10px */
  /* 1rem = 10px */
  font-size: 62.5%;
}

html body #root .App {
  min-height: 100vh;
}
